import React, {useEffect, useState} from 'react';
import {toast, Toaster} from "react-hot-toast";
import {
    MDBBadge,
    MDBBtn,
    MDBCardHeader,
    MDBCardTitle,
    MDBContainer,
    MDBInput,
    MDBListGroup,
    MDBListGroupItem,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader
} from "mdbreact";
import httpClient from "../../AxiosConfig";

const ProductCategory = () => {
    const [categories, setCategories] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState({})
    const [selectedSubCategory, setSelectedSubCategory] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [newCategory, setNewCategory] = useState({})
    const [categoryQueryEnded, setCategoryQueryEnded] = useState(false)

    useEffect(() => {
        if (categories.length <= 0 && !categoryQueryEnded) {
            setCategoryQueryEnded(true)
            getCategories()
        }
    }, [categories])

    const getCategories = () => {
        httpClient.get(`product/categories`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    setCategories(res.data)
                    const allCategories = []

                    res.data.forEach(item => {
                        allCategories.push({
                            id: item.id,
                            name: item.name
                        })

                        if (item.children?.length > 0) {
                            item.children.forEach(child => {
                                allCategories.push({
                                    id: child.id,
                                    name: child.name
                                })

                                if (child.children?.length > 0) {
                                    child.children.forEach(subChild => {
                                        allCategories.push({
                                            id: subChild.id,
                                            name: subChild.name
                                        })
                                    })
                                }
                            })
                        }
                    });

                    setAllCategories(allCategories)
                } else {
                    toast.error("Ndodhi nje gabim gjate shkarkimit te kategorive " + res.status);
                }
            }).catch(err => {
            toast.error("Ndodhi nje gabim gjate shkarkimit te kategorive " + err?.response?.status);
        })
    }

    const addCategory = () => {
        httpClient.post(`product/categories`, newCategory)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success("Ruajtja e kategorise u be me sukses!");
                    getCategories()
                    setNewCategory({})
                    setShowModal(false);
                } else {
                    toast.error("Ndodhi nje gabim gjate ruajtjes se kategorise " + res.status);
                }
            }).catch(err => {
            toast.error("Ndodhi nje gabim gjate ruajtjes se kategorise " + err?.response?.status);
        })
    }

    const handleGetValue = (value) => {
        newCategory.name = value
        setNewCategory(newCategory)
    }

    const handleGetSelect = (e) => {
        if (!e.target.value || parseInt(e.target.value) <= 0) return

        newCategory.parentId = e.target.value.length <= 0 ? null : parseInt(e.target.value)
        setNewCategory(newCategory)
    }

    return (
        <div>
            <div><Toaster/></div>

            <MDBCardHeader className="title-and-button">
                <MDBCardTitle className="panel-tittle">Lista e kategorive</MDBCardTitle>
                <span>
                        <MDBBtn className="add-product btn-outline-blue" onClick={() => setShowModal(true)}>Categori e re
                    </MDBBtn>
                </span>
            </MDBCardHeader>

            <MDBContainer className={'d-flex row'}>
                <MDBListGroup className={"col-11 col-md-5 overflow-hidden"}
                              style={{paddingTop: "2em", paddingBottom: "2em"}}>
                    {
                        categories.map(item => {
                            return <MDBListGroupItem key={item.id}
                                                     className={"d-flex justify-content-between align-items-center list-group-item-category"}
                                                     style={{paddingLeft: '1em'}}
                                                     onClick={() => {
                                                         setSelectedCategory(item)
                                                         setSelectedSubCategory({})
                                                     }}
                            >
                                {item.name}
                                {item.children?.length > 0 &&
                                    <MDBBadge color="warning" pill>{item.children?.length}</MDBBadge>}
                            </MDBListGroupItem>
                        })
                    }
                </MDBListGroup>

                <MDBListGroup className={"col-11 col-md-4 overflow-hidden"}
                              style={{paddingTop: "3em", paddingBottom: "2em"}}>
                    {
                        selectedCategory?.children?.map(item => {
                            return <MDBListGroupItem key={item.id}
                                                     className={"d-flex justify-content-between align-items-center list-group-item-category"}
                                                     style={{paddingLeft: '1em'}}
                                                     onClick={() => {
                                                         setSelectedSubCategory(item)
                                                     }}
                            >
                                {item.name}
                                {item.children?.length > 0 &&
                                    <MDBBadge color="warning" pill>{item.children?.length}</MDBBadge>}
                            </MDBListGroupItem>
                        })
                    }
                </MDBListGroup>

                <MDBListGroup className={"col-11 col-md-3 overflow-hidden"}
                              style={{paddingTop: "4em", paddingBottom: "2em"}}>
                    {
                        selectedSubCategory?.children?.map(item => {
                            return <MDBListGroupItem key={item.id}
                                                     className={"d-flex justify-content-between align-items-center list-group-item-category"}
                                                     style={{paddingLeft: '1em'}}
                            >
                                {item.name}
                                {item.children?.length > 0 &&
                                    <MDBBadge color="warning" pill>{item.children?.length}</MDBBadge>}
                            </MDBListGroupItem>
                        })
                    }
                </MDBListGroup>
            </MDBContainer>

            <MDBModal className="product-modal" isOpen={showModal}
                      toggle={() => setShowModal(!showModal)}
                      centered>
                <MDBModalHeader className="modal-title" toggle={() => setShowModal(!showModal)}>Të dhënat e
                    kategorisë
                </MDBModalHeader>
                <MDBModalBody>
                    <p>Vendos të dhënat për Kategorinë</p>

                    <hr className="modal-hr"/>

                    <MDBInput
                        label="Emri i kategorisë:"
                        className="modal-input-text"
                        value={newCategory.name}
                        placeholder={newCategory.name}
                        getValue={handleGetValue}
                    />

                    <select className="browser-default custom-select mt-3"
                            onChange={handleGetSelect}
                    >
                        <option value={""}>Kategoria prind (nëse ka)</option>

                        {
                            allCategories.map(item => {
                                return <option value={item.id.toString()} key={item.id}>{item.name}</option>
                            })
                        }
                    </select>

                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn className="modal-btns" color="success"
                            onClick={addCategory}
                    ><i className="far fa-edit"/> &nbsp;Krijo
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </div>
    );
};
export default ProductCategory;
