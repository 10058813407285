import React, {Component} from "react";
import httpClient from "../../AxiosConfig";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow,
} from "mdbreact";
import "../../index.css"
import {MDBFooter} from "mdb-react-ui-kit";
import {toast, Toaster} from "react-hot-toast";
import {confirmAlert} from "react-confirm-alert";


class Offers extends Component {
    state = {
        offers: [],
        showModal: false,
        offer: {},
        isCreating: false,
        loading: true
    }

    toggleModal = (offer = {}) => {
        this.setState({
            showModal: !this.state.showModal,
            isCreating: false,
            offer: offer
        });
    };

    toggleCreatingModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            isCreating: true,
            offer: {}
        });
    };

    getMyOffers = () => {
        httpClient.get(`geonet/offer`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success("Ofertat u shkarkuan me sukses!");
                    this.setState({offers: res.data, loading: false});
                } else {
                    toast.error("Gabim gjatë shkarkimit të ofertave." + res.status);
                }
            })
            .catch(err => {
                toast.error("Gabim gjatë shkarkimit të ofertave." + err?.response?.status)
            })
    }

    toggleOfferAndSetOffer = (offer) => {
        this.setState({
            showModal: !this.state.showModal,
            offer: offer,
            isCreating: false
        })
    }

    handleGetValue = (newValue) => {
        let offer = this.state.offer;
        if (!offer) return;
        offer.emri = newValue
        this.setState({
            offer: offer
        });
    }

    componentDidMount() {
        this.getMyOffers();
    }


    updateOrCreateOffer = () => {
        console.log(this.state.isCreating)
        console.log(this.state.offer)

        if (!this.state.offer?.emri || this.state.offer.emri?.length <= 0) {
            toast.error("Të plotësohet fusha e ofertës!");
            return;
        }
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: this.state.isCreating ? 'Konfirmo ofertën e re' : 'Keni ndryshuar ofertën',
                message: 'Dëshironi të konfirmoni veprimin?',
                buttons: [
                    {
                        label: 'Po',

                        onClick: () => {
                            if (this.state.isCreating)
                                this.createOffer()
                            else
                                this.updateMyOffer()
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                            this.getMyOffers();
                        }
                    }
                ]
            });
        })
    }

    deleteMyOffer = (id) => {
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: 'Keni kërkuar fshirjen e ofertës',
                message: 'Dëshironi të konfirmoni fshirjen?',
                buttons: [
                    {
                        label: 'Po',
                        onClick: () => {
                            if (this.state.offer?.id <= 0) {
                                toast.error("Zgjedhni në produkt për të fshirë")
                                return
                            }
                            httpClient.delete(`Geonet/Offer/` + id)
                                .then(res => {
                                    if (200 <= res.status && res.status < 400) {
                                        toast.success("Oferta u fshi!");
                                        this.getMyOffers();
                                    } else {
                                        toast.error("Fshirja e ofertës dështoi! " + res.status);
                                    }

                                }).catch(err => {
                                toast.error("Fshirja e ofertës dështoi! " + err?.response?.status);
                            })
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                        }
                    }
                ]
            });
        })

    }

    updateMyOffer = () => {
        httpClient.put(`geonet/offer`, this.state.offer)
            .then(res => {
                console.log(res.data)

                if (200 <= res.status && res.status < 400) {
                    toast.success('Oferta u përditësua me sukses!');
                    this.getMyOffers();
                } else {
                    toast.error("Ndodhi një gabim gjatë përditësimit të ofertës." + res.status)
                }
            }).catch((err) => {
            toast.error("Ndodhi një gabim gjatë përditësimit të ofertës." + err?.response?.status);
        })
    }

    createOffer = () => {
        if (!this.state.offer?.emri || this.state.offer.emri?.length <= 0) {
            toast.error("Të mbushet emri i ofertës!");
            return;
        } else if (!this.state.offer?.detaje || this.state.offer.detaje?.length <= 0) {
            toast.error("Të mbushet përshkrimi i ofertës!");
            return;
        } else if (!this.state.offer?.cmimi || this.state.offer.cmimi?.length <= 0) {
            toast.error("Të vendoset çmimi!");
            return;
        }
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: this.state.isCreating ? 'Konfirmo ofertën e re' : 'Keni ndryshuar ofertën',
                message: 'Dëshironi të konfirmoni ofertën?',
                buttons: [
                    {
                        label: 'Po',
                        onClick: () => {
                            if (this.state.isCreating)
                                this.createMyOffer()
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                        }
                    }
                ]
            });
        })
    }


    handleGetValueOffer = (newValue) => {
        let offer = this.state.offer;
        if (!offer) return;
        offer.detaje = newValue
        this.setState({
            offer: offer
        });
    }

    handleGetValuePrice = (newValue) => {
        let offer = this.state.offer;
        if (!offer) return;
        offer.cmimi = newValue
        this.setState({
            offer: offer
        });
    }

    createMyOffer = () => {
        httpClient.post(`Geonet/Offer`, this.state.offer)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.getMyOffers();
                    toast.success("U krijua oferta!");
                } else {
                    toast.error("Krijimi i ofertës dështoi! " + res.status);
                }

            }).catch(err => {
            toast.error("Krijimi i ofertës dështoi! " + err?.response?.status);
        })
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <div className="spinner-border text-primary clients-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>

                            <div><Toaster/></div>
                            <MDBCardHeader className="title-and-button">
                                <MDBCardTitle className="panel-tittle offer-title">Lista e ofertave</MDBCardTitle>
                                <span>
                        <MDBBtn className="add-product btn-outline-blue" onClick={this.toggleCreatingModal}>Ofertë e re
                    </MDBBtn>
                    </span>
                            </MDBCardHeader>
                            <MDBRow className="clients-container">
                                {
                                    this.state.offers
                                        .map((offer, key) => (
                                                <MDBCard key={key}
                                                         className="offer-card m-2 col-11 col-md-5 ">
                                                    <MDBBadge className="product-badge offer-badge">
                                                        <div className="offer-type">Oferta</div>
                                                        <button className="product-button offer-button"
                                                                onClick={() => this.toggleModal(offer)}>
                                                            Përditëso
                                                        </button>
                                                    </MDBBadge>
                                                    <MDBCardTitle className="name-offer">{offer.emri}</MDBCardTitle>
                                                    <div className="detail-offer">
                                                        <div className="offer-details offer-container">{offer.detaje}</div>
                                                        <MDBBadge
                                                            className="offer-details offer-badge-price">Çmimi: &nbsp;<span
                                                            className="offers-details offer-price">{offer.cmimi}<MDBIcon
                                                            icon="euro-sign"/></span></MDBBadge>
                                                    </div>
                                                    <MDBFooter className="footer-offer">Aktive</MDBFooter>
                                                </MDBCard>

                                            )
                                        )
                                }

                                <MDBModal className="client-md-update" isOpen={this.state.showModal}
                                          toggle={this.toggleModal}
                                          centered>
                                    <MDBModalHeader className="modal-title"
                                                    toggle={this.toggleModal}>Oferta</MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Emri i ofertës:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.offer?.emri}
                                                    placeholder={this.state.offer?.emri}
                                                    getValue={this.handleGetValue}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Përshkrimi i ofertës:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.offer?.detaje}
                                                    placeholder={this.state.offer?.detaje}
                                                    getValue={this.handleGetValueOffer}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Çmimi:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.offer?.cmimi}
                                                    placeholder={this.state.offer?.cmimi}
                                                    getValue={this.handleGetValuePrice}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                    </MDBModalBody>
                                    <MDBModalFooter className="client-modal-footer">
                                        <MDBBtn className="modal-btns" color="danger"
                                                onClick={() => this.deleteMyOffer(this.state.offer.id)}><i
                                            className="fas fa-trash"/> &nbsp;Anulo </MDBBtn>
                                        <MDBBtn className="modal-btns" color="success"
                                                onClick={this.updateOrCreateOffer}
                                        ><i className="far fa-edit"/> &nbsp; {this.state.isCreating ? "Krijo" : "Përditëso"}
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModal>
                            </MDBRow>
                        </>
                }
            </>
        );
    }
}

export default Offers;