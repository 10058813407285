import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import httpClient from "../../../AxiosConfig";
import {MDBCard, MDBCardImage, MDBCol} from "mdb-react-ui-kit";
import {apiHost} from "../../../Settings";
import {
    MDBBtn,
    MDBCardBody,
    MDBCardTitle,
    MDBContainer,
    MDBIcon,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow
} from "mdbreact";
import "../../../index.css"
import {toast, Toaster} from "react-hot-toast";
import Dropzone from "react-dropzone";
import Select from "react-select";
import {confirmAlert} from "react-confirm-alert";

const CaseDetails = () => {
    let {caseId} = useParams();
    const [caseDetails, setCaseDetails] = useState({});
    const [caseComments, setCaseComments] = useState([]);
    const [userId, setUserId] = useState("-1");
    const [username, setUsername] = useState("");
    const [comment, setComment] = useState('')
    const [files, setFiles] = useState([]);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [users, setUsers] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [closedComment, setClosedComment] = useState('');

    useEffect(() => {
        setUserId(localStorage.getItem('id'))
        setUsername(localStorage.getItem('username'))
        setIsAdmin(parseInt(localStorage.getItem('isAdmin')) === 1)
        getCaseDetails();
        getCaseComments();
        getUser();
    }, [])


    const getUser = () => {
        httpClient.get(`user/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    setUsers(res.data);
                }
            })
            .catch(error => {
                toast.error("Ndodhi një gabim gjatë shkarkimit." + error?.response?.status)
            })
    }

    const getCaseDetails = () => {
        httpClient.get(`case/casedetails?caseid=${caseId}`)
            .then(res => {
                setCaseDetails(res.data)
            }).catch(err => {
            // toast
        })
    }

    const getCaseComments = () => {
        // validate id case id is null return

        httpClient.get(`case/casecomments?caseid=${caseId}`)
            .then(res => {
                setCaseComments(res.data)
            }).catch(err => {
            // toast
        })
    }

    const addComment = () => {
        // if (caseDetails.assignedToId !== parseInt(userId)) {
        //     toast.error("Nuk mund të komentoni në këtë postim!")
        //     return;
        // }

        if (comment?.trim().length <= 0 && !isUploadingFile)
            return;

        if (isUploadingFile) {
            if (files.length <= 0) {
                toast.error("Duhet te zgjidhni nje dokument per te vazhduar")
                return;
            }

            let formData = new FormData();

            formData.append('file', files[0]);
            formData.append('caseid', caseId);
            formData.append('createdBy', parseInt(userId));
            formData.append('createdByName', username);

            httpClient.post('case/addcommentfile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                if (response.status === 200) {
                    getCaseComments();
                    setComment('');
                    setFiles([])
                } else {
                    toast.error("Ndodhi nje gabim gjate ruatjes se komentit " + response.status)

                }
            }).catch(error => {
                toast.error("Ndodhi nje gabim gjate ruatjes se komentit " + error?.response?.status)

            })
                .finally(() => setIsUploadingFile(false))
        } else {
            httpClient.post('case/addcomment', {
                caseid: caseId,
                createdBy: parseInt(userId),
                createdByName: username,
                Comment: comment
            }).then(response => {
                if (response.status === 200) {
                    getCaseComments();
                    setComment('');
                    setFiles([])
                } else {
                    toast.error("Ndodhi nje gabim gjate ruatjes se komentit " + response.status)

                }
            }).catch(error => {
                toast.error("Ndodhi nje gabim gjate ruatjes se komentit " + error?.response?.status)

            })
        }
    }

    const removeCommentFile = (commentId) => {
        if (!commentId) return

        confirmAlert({
            title: 'Keni kërkuar fshirjen e komentit',
            message: 'Dëshironi të konfirmoni fshirjen?',
            buttons: [
                {
                    label: 'Po',
                    onClick: () => {
                        httpClient.post(`Case/removecommentfile?caseId=${caseId}&commentId=${commentId}`)
                            .then(response => {
                                if (response.status === 200) {
                                    getCaseComments();
                                } else {
                                    toast.error("Ndodhi nje gabim gjate perditesimit te komentit " + response.status)

                                }
                            }).catch(error => {
                            toast.error("Ndodhi nje gabim gjate perditesimit te komentit " + error?.response?.status)
                        })
                    }
                },
                {
                    label: 'Jo',
                    onClick: () => {
                    }
                }
            ]
        });
    }

    const handleChangeUser = (selectedItem) => {
        if (selectedItem?.label?.length <= 0) {
            return;
        }

        if (userId === caseDetails.assignedToId) {
            toast("Perdorues i njejt, rasti nuk u perditesua!")
            return;
        }

        let newCaseDetails = Object.assign({}, caseDetails)
        newCaseDetails.assignedTo = selectedItem.label
        newCaseDetails.assignedToId = selectedItem.value
        setCaseDetails(newCaseDetails)

        httpClient.post(`case/update-assigned?id=${caseId}&userId=${selectedItem.value}`)
            .then(res => {
                if (res.status === 200) {
                    toast.success("Rastit iu ndryshua moderatori me sukses")
                } else {
                    toast.error("Gabim gjate ndryshimit te moderatorit " + res.status)
                }
            })
            .catch(error => {
                toast.error("Gabim gjate ndryshimit te moderatorit " + error?.response?.status)
            });
    }

    const closeCase = () => {
        if (closedComment.length <= 0) {
            toast.error("Duhet te shenoni nje koment")
            return
        }

        httpClient.post(`case/closeCase`, {
            id: caseId,
            closedComment: closedComment,
            closedById: userId,
            closedByName: username
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success("Rastit iu ndryshua statusi me sukses")
                    setClosedComment("")
                    setShowModal(false)
                    getCaseDetails()
                } else {
                    toast.error("Gabim gjate ndryshimit te statusit " + res.status)
                }
            })
            .catch(error => {
                toast.error("Gabim gjate ndryshimit te statusit " + error?.response?.status)
            });
    }

    let fileName, fileName2;
    if (caseDetails?.caseDocument?.length >= 1) {
        let fileUrlArray = caseDetails.caseDocument.split('\\');
        if (fileUrlArray.length < 2)
            fileUrlArray = caseDetails.caseDocument.split('/');
        fileName = fileUrlArray[fileUrlArray.length - 1];
    }
    if (caseDetails?.caseDocument2?.length >= 1) {
        let fileUrlArray = caseDetails.caseDocument2.split('\\');
        if (fileUrlArray.length < 2)
            fileUrlArray = caseDetails.caseDocument2.split('/');
        fileName2 = fileUrlArray[fileUrlArray.length - 1];
    }

    return <>

        <Toaster/>
        <MDBContainer className="postings-row">
            <MDBCol className="col-md-6 col-sm-11 postings-col">
                <MDBCard className="postings-card">
                    <MDBCardTitle className="posting-card-title">Detajet</MDBCardTitle>
                    <div className="img-div">
                        <MDBCardImage className="img-in-posting"
                                      src={apiHost + (caseDetails.casePhoto ?? "/CasePhotos/no-image.png")}/>
                    </div>
                    <MDBCardBody>
                        <hr/>
                        <div className="info-card">
                            <div className="case-title">
                                <MDBRow>
                                    <h6 className="subject-issue">Regjistruar nga:</h6> &nbsp;
                                    <h6> {caseDetails.registeredBy}</h6>
                                </MDBRow>
                            </div>
                            <div className="case-title">
                                <MDBRow>
                                    <h6 className="subject-issue">Klienti:</h6> &nbsp;
                                    <h6> {caseDetails.client}</h6>
                                </MDBRow>
                            </div>
                            <div className="case-title">
                                <MDBRow>
                                    <h6 className="subject-issue">Subjekti:</h6> &nbsp;
                                    <h6> {caseDetails.subject}</h6>
                                </MDBRow>
                            </div>
                            <div className="case-title">
                                <MDBRow>
                                    <h6 className="subject-issue">Përshkrimi:</h6> &nbsp;
                                    <h6> {caseDetails.description}</h6>
                                </MDBRow>
                            </div>

                            {
                                fileName && <div className="case-title">
                                    <MDBRow>
                                        <h6 className="subject-issue">Dokument:</h6> &nbsp;
                                        <a href={apiHost + caseDetails.caseDocument} target={'_blank'}
                                           className={'text-secondary'}>
                                            <h6>{fileName}</h6></a>

                                        {fileName2 && <a href={apiHost + caseDetails.caseDocument2} target={'_blank'}
                                                         className={'text-secondary'}>
                                            <h6> {fileName2}</h6></a>}
                                    </MDBRow>
                                </div>
                            }

                            <div className="case-title">
                                <MDBRow>
                                    <h6 className="subject-issue">Produkti:</h6> &nbsp;
                                    <h6>  {caseDetails.product}</h6>
                                </MDBRow>
                            </div>
                            {caseDetails.statusId === 1 && isAdmin && <>
                                <hr className="hr-postings"/>
                                <MDBRow className="assigned-to">
                                    <MDBCol className="col-lg-4">
                                        <h6 className="case-title assigned-to-title">Caktuar për:</h6>
                                    </MDBCol>
                                    <MDBCol className="col-lg-7">
                                        <Select
                                            value={{
                                                label: caseDetails.assignedTo,
                                                value: caseDetails.assignedToId?.toString()
                                            }}
                                            onChange={handleChangeUser}
                                            options={users?.length >= 1 ? users?.map(user => ({
                                                value: user.id?.toString(),
                                                label: user.firstname + " " + user.lastname,
                                            })) : []}
                                        />
                                    </MDBCol>

                                </MDBRow></>}
                            <hr className="hr-statusi"/>
                        </div>

                        <MDBRow className="close-case-container d-flex justify-content-center align-items-center mt-4">
                            <MDBRow className="card-status-title  col-11">
                                <div className="card-status-title">
                                    <h6 className="case-status">Statusi:</h6>
                                </div>
                                <div className="card-status">{parseInt(caseDetails.statusId) === 1
                                    ? <div className="open-status">Hapur </div> :
                                    <div className="clossed-status">Mbyllur </div>}

                                </div>
                            </MDBRow>
                            {parseInt(caseDetails.statusId) === 1 &&
                                <MDBRow className=" card-status-title col-11">
                                    <MDBBtn className=" close-case-btn"
                                            onClick={() => setShowModal(true)}>
                                        Mbyll rastin
                                    </MDBBtn>
                                </MDBRow>
                            }
                        </MDBRow>


                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol className=" col-md-6 col-sm-11 postings-col">
                <MDBCard className="postings-card comments d-flex justify-content-between">
                    <MDBCardTitle className="posting-card-title comments-card">Komentet</MDBCardTitle>
                    <Dropzone
                        disabled={parseInt(caseDetails.statusId) !== 1}
                        onDrop={acceptedFiles => {
                            if (acceptedFiles.length > 1) {
                                toast("Nuk mund të ngarkoni më shumë se një dokument.")
                                acceptedFiles.splice(1, acceptedFiles.length - 1)
                            }

                            setIsUploadingFile(true)
                            setFiles(acceptedFiles)
                            addComment()
                        }}
                    >
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <section>
                                <div {...getRootProps({onClick: event => event.stopPropagation()})}>
                                    <input {...getInputProps()} />

                                    {isDragActive ?
                                        <div id="respond"
                                             className="comments-div d-flex justify-content-center align-items-center"
                                             style={{background: "lightblue"}}
                                        >Dokumenti këtu</div>
                                        : <MDBContainer id="respond" className="comments-div">
                                            {
                                                caseComments.map((comment, key) => {
                                                    let fileName;
                                                    if (comment?.fileUrl?.length >= 1) {
                                                        let fileUrlArray = comment.fileUrl.split('\\');
                                                        if (fileUrlArray.length < 2)
                                                            fileUrlArray = comment.fileUrl.split('/');
                                                        fileName = fileUrlArray[fileUrlArray.length - 1];
                                                    }

                                                    return <MDBRow
                                                        key={key}
                                                        className={comment.createdBy.toString() === userId.toString() ? "comments-row justify-content-end" : "comments-row"}
                                                    >
                                                        {comment.createdBy.toString() !== userId.toString() ? <> <MDBCol
                                                                className="col-1 comments-2pt mr-1 d-flex justify-content-center align-items-center">{
                                                                caseDetails?.assignedTo?.split('')[0]?.toUpperCase()}</MDBCol>
                                                                <MDBCol
                                                                    className="col-8 comments-8pt d-flex justify-content-start align-items-center">
                                                                    {comment.fileUrl?.length >= 1 ? <a target={'_blank'}
                                                                                                       href={apiHost + comment.fileUrl}>{fileName}</a>

                                                                        : comment.comment}
                                                                </MDBCol>
                                                                <div className="comment-date-left">{comment.createdOn}</div>
                                                            </> :

                                                            <> {
                                                                comment.fileUrl?.length >= 1 && <MDBBtn
                                                                    className={'deleteCommentBtn ml-3'}
                                                                    onClick={() => removeCommentFile(comment.id)}
                                                                    color={'danger'}><MDBIcon
                                                                    icon={'trash'}/>
                                                                </MDBBtn>
                                                            }

                                                                <MDBCol
                                                                    className="col-8 comments-8pt d-flex justify-content-start align-items-center">
                                                                    {comment.fileUrl?.length >= 1 ? <><a
                                                                        target={'_blank'}
                                                                        href={apiHost + comment.fileUrl}>{fileName}</a> </> : comment.comment}
                                                                </MDBCol><MDBCol
                                                                    className="col-1 comments-2pt ml-1 d-flex justify-content-center align-items-center">{
                                                                    username?.split('')[0]?.toUpperCase()}</MDBCol>
                                                                <div className="comment-date">{comment.createdOn}</div>
                                                            </>}

                                                    </MDBRow>
                                                })
                                            }

                                        </MDBContainer>}
                                    <div className={'d-flex justify-content-between align-items-center'}>
                                        <div className="msg-div">
                                            <MDBInput
                                                value={comment}
                                                getValue={(value) => setComment(value)}
                                                className="your-msg" label={'Mesazhi i juaj'}/>
                                        </div>
                                        <MDBCol className="msg-btns">
                                            <Dropzone
                                                disabled={parseInt(caseDetails.statusId) !== 1}
                                                onDrop={acceptedFiles => {
                                                    if (acceptedFiles.length > 1) {
                                                        toast("Nuk mund të ngarkoni më shumë se një dokument.")
                                                        acceptedFiles.splice(1, acceptedFiles.length - 1)
                                                    }

                                                    setIsUploadingFile(true)
                                                    setFiles(acceptedFiles)
                                                    addComment()
                                                }}>
                                                {({getRootProps, getInputProps, isDragActive}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />

                                                            <MDBBtn disabled={parseInt(caseDetails.statusId) !== 1}
                                                                    className="btn-outline posting-btn">Ngarko&nbsp;&nbsp;
                                                                <MDBIcon
                                                                    icon={'paperclip'}/> </MDBBtn>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                            <MDBBtn disabled={parseInt(caseDetails.statusId) !== 1}
                                                    className="btn-outline posting-btn"
                                                    onClick={addComment}
                                            >Dërgo&nbsp;&nbsp;<MDBIcon far
                                                                       icon="paper-plane"/></MDBBtn>
                                        </MDBCol>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>


                </MDBCard>
            </MDBCol>
        </MDBContainer>

        <MDBModal className="client-md-update" isOpen={showModal} toggle={() => setShowModal(!showModal)} centered>
            <MDBModalHeader className="modal-title"
                            toggle={() => setShowModal(!showModal)}>Statusi i rastit</MDBModalHeader>
            <MDBModalBody className="close-case-md-body">
                <h2 className="close-case-title">A dëshironi të mbyllni rastin?</h2>
                <div>
                    <textarea label="Komenti juaj"
                              onChange={e => setClosedComment(e.target.value)}
                              value={closedComment}
                              className="text-area-comment"
                    >

                    </textarea>

                </div>
            </MDBModalBody>
            <MDBModalFooter className="client-modal-footer">
                <MDBBtn className="modal-btns" color="blue" onClick={() => setShowModal(false)}>
                    &nbsp;Anulo </MDBBtn>
                <MDBBtn className="modal-btns " color="danger"
                        onClick={closeCase}
                >&nbsp;Mbylle</MDBBtn>
            </MDBModalFooter>
        </MDBModal>


    </>


};

export default CaseDetails;
