import React, {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBNav,
    MDBNavItem,
    MDBRow,
    MDBTabContent,
    MDBTabPane
} from "mdbreact";
import {Link} from "react-router-dom";
import Cases from "./sections/Cases";
import TeamCases from "./sections/TeamCases";
import httpClient from "../../AxiosConfig";
import "../../index.css"
import {toast, Toaster} from "react-hot-toast";
import Select from 'react-select';
import './Postings.css'
import Dropzone from 'react-dropzone'

const Postings = () => {
    const [activeItem, setActiveItem] = useState("1");
    const [showModal, setShowModal] = useState(false);
    const [getClient, setGetClient] = useState([]);
    const [getProduct, setGetProduct] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [users, setUsers] = useState([]);
    const [files, setFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cases, setCases] = useState([]);
    const [showAllCases, setShowAllCases] = useState(false);

    const toggle = tab => {
        if (activeItem !== tab) {
            setActiveItem(tab);
        }
    };

    const createPosting = () => {
        if (selectedClient === null || selectedClient.label?.length === 0 || selectedClient.value?.length === 0) {
            toast.error("Të mbushet fusha klienti!");
            return;
        } else if (selectedProduct === null || selectedProduct.label?.length === 0 || selectedProduct.value?.length === 0) {
            toast.error("Të mbushet fusha produkti!");
            return;
        } else if (description === null || description.label?.length === 0 || description.value?.length === 0) {
            toast.error("Të mbushet fusha produkti!");
            return;
        } else if (selectedUser === null || selectedUser.label?.length === 0 || selectedUser.value?.length === 0) {
            toast.error("Të mbushet fusha përdoruesi!");
            return;
        }

        setLoading(true);
        let formData = new FormData();
        if (images?.length > 0) {
            formData.append('file', images[0])
        }

        const userId = localStorage.getItem('id')
        const username = localStorage.getItem('username')
        const isAdmin = localStorage.getItem('isAdmin') === "1";

        formData.append('clientid', selectedClient.value);
        formData.append('productid', selectedProduct.value);
        formData.append('createdBy', userId);
        formData.append('createdByName', username);
        formData.append('subject', subject);
        formData.append('description', description);
        formData.append('assignTo', isAdmin ? selectedUser.value : '-1');

        if (files?.length > 0) {
            formData.append('document', files[0])
            if (files.length === 2) {
                formData.append('document2', files[1])
            }
        }

        const adminIds = users.filter(u => u.userGroupId === 1).map(u => u.id).join();
        formData.append('notifyUsers', adminIds)

        toast("Postimi është dërguar për ruajtje, prisni një moment...")

        httpClient.post(`case/add`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                if (res.status === 200) {
                    toast.success("Postimi u ruajt me sukses")

                    setShowModal(false)
                    setGetProduct([])
                    setGetClient([])
                    setSelectedClient(null)
                    setSelectedProduct(null)
                    setSelectedUser(null)
                    setSubject("")
                    setDescription("")
                    setFiles([])
                    setImages([])

                    getMyCases()
                } else {
                    toast.error("Gabim gjatë ruajtjes së postimit " + res?.status)
                }
            })
            .catch(err => {
                toast.error("Gabim gjatë ruajtjes së postimit " + err?.response?.status)
            })
            .finally(() => setLoading(false))
    }

    const getMyClient = () => {
        httpClient.get(`client/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    setGetClient(res.data);
                }
            })
            .catch(error => {
                toast.error("Ndodhi një gabim gjatë shkarkimit." + error?.response?.status)
            })
    }
    const getMyProduct = () => {
        httpClient.get(`product/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    setGetProduct(res.data);
                }
            })
            .catch(error => {
                toast.error("Ndodhi një gabim gjatë shkarkimit." + error?.response?.status)
            })
    }
    const getUser = () => {
        httpClient.get(`user/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    setUsers(res.data);
                }
            })
            .catch(error => {
                toast.error("Ndodhi një gabim gjatë shkarkimit." + error?.response?.status)
            })
    }
    useEffect(() => {
        getMyCases()
        getMyClient()
        getMyProduct()
        getUser()
    }, []);

    const handleChangeClient = (selectedOption) => {
        setSelectedClient(selectedOption)
    };
    const handleChangeProduct = (selectedOption) => {
        setSelectedProduct(selectedOption)
    };
    const handleChangeUser = (selectedOption) => {
        setSelectedUser(selectedOption)
    };

    const getMyCases = () => {
        const userId = localStorage.getItem('id')

        httpClient.get(`case/mycases?userid=${userId}`)
            .then(res => {
                if (res.status >= 200 && res.status < 400) {
                    const cases = res.data;
                    setCases(cases)
                    setLoading(false)

                    toast.success('Postimet u shkarkuan me sukses!')
                } else {
                    toast.error('Ndodhi një gabim gjatë shkarkimit të postimeve ' + res.status)
                }
            })
            .catch(err => {
                toast.error('Ndodhi një gabim gjatë shkarkimit të postimeve')
            })
            .finally(() => setLoading(false))
    }

    const handleChangeStatus = (event) => {
        setShowAllCases(event.target.checked)
    }

    return (
        <>
            <Toaster/>
            <MDBContainer className='fluid'>
                <MDBCardHeader className="title-and-button">
                    <MDBCardTitle className="panel-tittle postings-list mb-5">Lista e postimeve</MDBCardTitle>
                    {activeItem === '1' && <span>
                            <MDBBtn className="add-product btn-outline-blue" onClick={() => setShowModal(true)}>Postim i ri
                            </MDBBtn>
                        </span>}

                </MDBCardHeader>

                <MDBNav className="nav-tabs">

                    <MDBNavItem className="tab-title"
                                style={activeItem === '1' ? {color: "#007bff", fontSize: "18px",} : null}>
                        <Link className="tab-title" to="#" active={(activeItem === "2").toString()}
                              onClick={() => toggle("1")}
                              role="tab">
                            Postimet e mia
                        </Link>
                    </MDBNavItem>
                    <MDBNavItem className=""
                                style={activeItem === '2' ? {color: "#007bff", fontSize: "18px",} : null}>
                        <Link className="tab-title" to="#" active={(activeItem === "1").toString()}
                              onClick={() => toggle("2")}
                              role="tab">
                            Postimet e ekipit
                        </Link>
                    </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={activeItem}>
                    <MDBTabPane tabId="1" role="tabpanel">
                        <div className="custom-control custom-checkbox display-all-cases">
                            <input type="checkbox" className="custom-control-input" id="defaultChecked"
                                   onChange={handleChangeStatus}
                            />
                            <label className="custom-control-label" htmlFor="defaultChecked">Shfaq te gjitha
                                postimet</label>
                        </div>
                        <Cases cases={cases} loading={loading} showCases={showAllCases}/>
                    </MDBTabPane>

                    <MDBTabPane tabId="2" role="tabpanel">
                        <div className="custom-control custom-checkbox display-all-cases">
                            <input type="checkbox" className="custom-control-input" id="defaultChecked2"
                                   onChange={handleChangeStatus}
                            />
                            <label className="custom-control-label" htmlFor="defaultChecked2">Shfaq te gjitha
                                postimet</label>
                        </div>
                        <TeamCases showCases={showAllCases}/>
                    </MDBTabPane>


                </MDBTabContent>
            </MDBContainer>


            <MDBModal className="client-md-update" isOpen={showModal} toggle={() => setShowModal(!showModal)} centered>
                <MDBModalHeader className="modal-title"
                                toggle={() => setShowModal(!showModal)}>Postim i ri
                </MDBModalHeader>
                <MDBModalBody>

                    <MDBRow>
                        <MDBCol className="modal-label">
                            <label>Klientët:</label>
                        </MDBCol>
                        <MDBCol className="modal-info">
                            <Select
                                value={selectedClient}
                                onChange={handleChangeClient}
                                options={getClient?.length >= 1 ? getClient?.map(client => ({
                                    value: client.id?.toString(),
                                    label: client.fullname?.toString()
                                })) : []}
                            />
                        </MDBCol>
                    </MDBRow>
                    <hr className="modal-hr"/>
                    <MDBRow>
                        <MDBCol className="modal-label">
                            <label>Produktet:</label>
                        </MDBCol>
                        <MDBCol className="modal-info">
                            <Select
                                value={selectedProduct}
                                onChange={handleChangeProduct}
                                options={getProduct?.length >= 1 ? getProduct?.map(product => ({
                                    value: product.id?.toString(),
                                    label: product.productName?.toString()
                                })) : []}
                            />
                        </MDBCol>
                    </MDBRow>
                    <hr className="modal-hr"/>
                    <MDBRow>
                        <MDBCol className="modal-label">
                            <label>Përshkrimi:</label>
                        </MDBCol>
                        <MDBCol className="modal-info">
                            <MDBInput
                                className="modal-input-client" type="text-box"
                                value={subject}
                                getValue={(value) => setSubject(value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <hr className="modal-hr"/>
                    <MDBRow>
                        <MDBCol className="modal-label">
                            <label>Detaje rreth problemit:</label>
                        </MDBCol>
                        <MDBCol className="modal-info">
                            <textarea className="form-control rounded-0" id="exampleFormControlTextarea2"
                                      rows="3"
                                      value={description}
                                      onChange={(e) => setDescription(e.target.value)}
                            />
                        </MDBCol>
                    </MDBRow>
                    <hr className="modal-hr"/>
                    <MDBRow>
                        <MDBCol className="modal-label">
                            <label>Zgjedh përdoruesin:</label>
                        </MDBCol>
                        <MDBCol className="modal-info">
                            <Select
                                value={selectedUser}
                                onChange={handleChangeUser}
                                options={users?.length >= 1 ? users?.map(user => ({
                                    value: user.id?.toString(),
                                    label: user.firstname + " " + user.lastname,
                                })) : []}
                            />
                        </MDBCol>
                    </MDBRow>
                    <hr className="modal-hr"/>
                    <MDBRow className="drag-and-drop-div">
                        <MDBCol className="modal-label col-6">
                            <label className="drag-and-drop">Dokumenti:</label>
                            <Dropzone onDrop={acceptedFiles => {
                                if (acceptedFiles.length > 2) {
                                    toast("Nuk mund të ngarkoni më shumë se dy dokumente.")
                                    acceptedFiles.splice(2, acceptedFiles.length - 1)
                                }
                                setFiles(acceptedFiles)
                            }}>
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            {
                                                isDragActive ?
                                                    <p className="drag-and-drop-text">Dokumenti këtu</p> :
                                                    <p className="drag-and-drop-text">Vendos apo kliko për të ngarkuar
                                                        dokumentin</p>
                                            }
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            {files?.length >= 1 && files.map(file => (
                                <p>{file.path}</p>
                            ))}

                        </MDBCol>
                        <MDBCol className="modal-label col-6">
                            <label className="drag-and-drop">Foto:</label>

                            <Dropzone onDrop={acceptedFiles => {
                                if (acceptedFiles.length > 1) {
                                    toast("Nuk mund të ngarkoni më shumë se një dokument.")
                                    acceptedFiles.splice(1, acceptedFiles.length - 1)
                                }

                                if (acceptedFiles[0].type !== "image/png" &&
                                    acceptedFiles[0].type !== "image/jpeg" &&
                                    acceptedFiles[0].type !== "image/jpg" &&
                                    acceptedFiles[0].type !== "image/gif") {
                                    toast.error("Mund të ngarkohet vetëm imazh")
                                    return;
                                }

                                setImages(acceptedFiles)
                            }}>
                                {({getRootProps, getInputProps, isDragActive}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />

                                            {
                                                isDragActive ?
                                                    <p className="drag-and-drop-text">Dokumenti këtu</p> :
                                                    <p className="drag-and-drop-text">Vendos apo kliko për të
                                                        ngarkuar foton</p>
                                            }
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                            {images?.length >= 1 && images.map(image => (
                                <p>{image.path}</p>
                            ))}
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter className="client-modal-footer">
                    <MDBBtn className="modal-btns" color="danger"
                            onClick={() => setShowModal(false)}
                    ><i
                        className="fas fa-trash"/> &nbsp;Anulo </MDBBtn>
                    <MDBBtn className="modal-btns" color="success"
                            onClick={() => createPosting()}
                    ><i className="far fa-edit"/> &nbsp;Krijo</MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        </>
    );
}
export default Postings;