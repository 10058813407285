import React, {useEffect, useState} from 'react';
import Routes from '../src/components/Routes';
import TopNavigation from './components/topNavigation';
import SideNavigation from './components/sideNavigation';
import Footer from './components/Footer';
import {Route, Switch} from "react-router-dom";
import SignIn from "./components/pages/SignIn";
import NotFoundPage from "./components/pages/NotFoundPage";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'leaflet/dist/leaflet.css';
import './index.css';

const App = () => {
    const [collapseId, setCollapseId] = useState('')
    const [hambMenu, setHambMenu] = useState(false)

    useEffect(() => {
        updateWindowDimensions();

        window.addEventListener('resize', updateWindowDimensions);
    }, [])

    const updateWindowDimensions = () => {
        if (window.innerWidth < 992) {
            setCollapseId('')
            setHambMenu(true)
        } else {
            setCollapseId('navcollapse')
            setHambMenu(false)
        }
    }

    const manageCollapseId = (id) => {
        setCollapseId(collapseId !== id ? id : '')
    }

    return (
        <Switch>
            <Route path='/signin' component={SignIn}/>
            <Route path='/404' component={NotFoundPage}/>

            <div className="flexible-content">
                <TopNavigation setCollapseId={manageCollapseId} menuDisplay={hambMenu}/>
                <SideNavigation collapseId={collapseId}/>
                <main id="content" className="p-5 main-container">
                    <Routes/>
                </main>
                <Footer/>
            </div>
        </Switch>
    );
}

export default App;
