import React from "react";
import {MDBContainer} from "mdbreact";
import "../../../index.css"
import CaseCard from "../CaseCard";


const Cases = ({cases, loading, showCases}) => {
    const casesToShow = showCases ? cases : cases.filter(caseDetails => caseDetails.statusId === 1);

    return (
        <MDBContainer className="cards-container row">
            {
                loading ? <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    : casesToShow.map((caseDetails, key) => <CaseCard
                        caseDetails={caseDetails}
                        key={key}
                    />)
            }
        </MDBContainer>
    );
}

export default Cases;