import {Redirect, Route} from 'react-router-dom'

import React from 'react'

function ProtectedRoute({ children, component: Component, ...props }) {
    const user = localStorage.getItem('token')

     return user
         ? (<Route {...props} component={Component} />)
         : (<Redirect to={'/signin' }/>)
}

export default ProtectedRoute