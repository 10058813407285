import React, {Component} from "react";
import logo from "./pages/Geotech-logo-new.png";
import {MDBCollapse, MDBIcon, MDBListGroup, MDBListGroupItem} from 'mdbreact';
import {Link} from "react-router-dom";
import "../index.css"

class SideNavigation extends Component {
    state = {
        collapseID: "geotech-collapse"
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    render() {
        return (
            <>
                <MDBCollapse id={'navcollapse'} isOpen={this.props.collapseId}>
                    <div className="sidebar-fixed position-fixed sidebar-padding">
                        <div
                            className="d-flex justify-content-center align-items-center img-side-div ">
                            <div className={'shadow-box-example z-depth-1'} style={{
                                height: '50%',
                                width: '90%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '1px'
                            }}>
                                <Link to="/" className="logo-wrapper waves-effect">
                                    <img alt="Geotech Logo" className="img-fluid"
                                         src={logo}/>
                                </Link>
                            </div>
                        </div>

                        <MDBListGroup className="sidebar-links">
                            <MDBListGroupItem className="sidebar-list-item sidebar-elements"
                                              onClick={this.toggleCollapse("geotech-collapse")}
                            > &nbsp;&nbsp;&nbsp;
                                Geotech
                                <span className="submenu-icon"><MDBIcon icon="angle-down"/></span>
                            </MDBListGroupItem>

                            <MDBCollapse id="geotech-collapse" isOpen={this.state.collapseID}>
                                <ul className="group-list">
                                    <Link
                                        to={'/postings'}>
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Postimet</li>
                                    </Link>
                                    <Link
                                        to={'/clients'}>
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Klientet</li>
                                    </Link>
                                </ul>
                            </MDBCollapse>
                            <MDBListGroupItem className="sidebar-list-item sidebar-elements"
                                              color="primary"
                                              onClick={this.toggleCollapse("geonet-collapse")}
                            > &nbsp;&nbsp;&nbsp;
                                Geonet
                                <span className="submenu-icon"><MDBIcon icon="angle-down"/></span>
                            </MDBListGroupItem>

                            <MDBCollapse id="geonet-collapse" isOpen={this.state.collapseID}>
                                <ul className="group-list">
                                    <Link
                                        to="/offers">
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ofertat</li>
                                    </Link>
                                    <Link
                                        to="/maps">
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hartat</li>
                                    </Link>
                                </ul>
                            </MDBCollapse>

                            <MDBListGroupItem className="sidebar-list-item sidebar-elements"
                                              onClick={this.toggleCollapse("geotech-users")}
                            > &nbsp;&nbsp;&nbsp;
                                Përdoruesit
                                <span className="submenu-icon"><MDBIcon icon="angle-down"/></span>
                            </MDBListGroupItem>

                            <MDBCollapse id="geotech-users" isOpen={this.state.collapseID}>
                                <ul className="group-list">
                                    <Link
                                        to={'/users'}>
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Listo</li>
                                    </Link>
                                </ul>
                            </MDBCollapse>

                            <MDBListGroupItem className="sidebar-list-item sidebar-elements"
                                              onClick={this.toggleCollapse("geotech-products")}
                            > &nbsp;&nbsp;&nbsp;
                                Produktet
                                <span className="submenu-icon"><MDBIcon icon="angle-down"/></span>
                            </MDBListGroupItem>

                            <MDBCollapse id="geotech-products" isOpen={this.state.collapseID}>
                                <ul className="group-list">
                                    <Link
                                        to={'/products'}>
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Listo</li>
                                    </Link>
                                    <Link
                                        to={'/product-categories'}>
                                        <li className="submenu-list-element">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kategorite</li>
                                    </Link>
                                </ul>
                            </MDBCollapse>

                        </MDBListGroup>
                    </div>
                </MDBCollapse>
            </>
        );
    }
}

export default SideNavigation;
