import React, {Component} from "react";
import httpClient from "../../AxiosConfig";
import {
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow,
} from "mdbreact";
import "../../index.css"
import {MDBFooter} from "mdb-react-ui-kit";
import {toast, Toaster} from "react-hot-toast";
import {confirmAlert} from "react-confirm-alert";


class Clients extends Component {
    state = {
        clients: [],
        showModal: false,
        client: {},
        isCreating: false,
        loading: true
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    toggleModalAndSetProduct = (client) => {
        this.setState({
            showModal: !this.state.showModal,
            client: client,
            isCreating: false
        });
    };

    toggleCreatingModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            isCreating: true,
            client: {}
        });
    };

    getMyClients = () => {
        httpClient.get(`client/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.setState({clients: res.data, loading: false});

                } else {
                    toast.error('Gabim gjate shkarkimit te klienteve ' + res.status);
                }
            }).catch((err) => {
            toast.error('Gabim gjate shkarkimit te klienteve ' + err?.response?.status);
        })
    }

    componentDidMount() {
        this.getMyClients();
    }

    handleGetValueFullName = (newValue) => {
        let client = this.state.client;
        if (!client) return;
        client.fullName = newValue
        this.setState({
            client: client
        });
    }

    handleGetValueCEmail = (newValue) => {
        let client = this.state.client;
        if (!client) return;
        client.email = newValue
        this.setState({
            client: client
        });
    }

    handleGetValuePhone = (newValue) => {
        let client = this.state.client;
        if (!client) return;
        client.phone = newValue
        this.setState({
            client: client
        });
    }

    confirmDeleteMyClient = () => {
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: 'Konfirmo fshirjen e klientit.',
                message: 'Dëshironi të konfirmoni veprimin?',
                buttons: [
                    {
                        label: 'Po',

                        onClick: () => {
                            this.deleteMyClient()
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                        }
                    }
                ]
            })
        })
    }

    deleteMyClient = () => {
        httpClient.delete(`client/delete?id=` + this.state.client.id)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Klienti u fshi me sukses!');
                    this.getMyClients()
                } else {
                    toast.error('Gabim gjatë fshierjes së klientit ' + res.status);
                }
            }).catch(err => {
            toast.error('Gabim gjate fshierjes se klientit ' + err?.response?.status);
        })
    }


    updateOrCreateClient = () => {
        if (!this.state.client?.fullName || this.state.client.fullName?.length <= 0) {
            toast.error("Të mbushet fusha emri!");
            return;
        } else if (!this.state.client?.email || this.state.client.email?.length <= 0) {
            toast.error("Të mbushet fusha email!");
            return;
        } else if (!this.state.client?.phone || this.state.client.phone?.length <= 0) {
            toast.error("Të mbushet fusha telefoni!");
            return;
        }
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: this.state.isCreating ? 'Konfirmo klientin e ri' : 'Keni ndryshuar klientin',
                message: 'Dëshironi të konfirmoni veprimin?',
                buttons: [
                    {
                        label: 'Po',

                        onClick: () => {
                            if (this.state.isCreating)
                                this.createClient()
                            else
                                this.updateClient()
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                        }
                    }
                ]
            });
        })
    }

    updateClient = () => {
        httpClient.put(`client/update`, this.state.client)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Klienti u përditësua me sukses!');
                    this.getMyClients();
                } else {
                    toast.error("Ndodhi një gabim gjatë përditësimit të klientit." + res.status)
                }
            }).catch((error) => {
            toast.error("Ndodhi një gabim gjatë përditësimit të klientit." + error?.response?.status);
        })
    }

    createClient = () => {

        let client = this.state.client;

        httpClient.post(`client/create`, client)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Klienti u krijua me sukses!');
                    this.getMyClients();
                } else {
                    toast.error("Ndodhi një gabim gjatë krijimit të klientit." + res.status)
                }
            }).catch((error) => {
            toast.error("Ndodhi një gabim gjatë krijimit të klientit." + error?.response?.status);
        })
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <div className="spinner-border text-primary clients-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                            <Toaster/>
                            <MDBCardHeader className="title-and-button">
                                <MDBCardTitle className="panel-tittle">Lista e klienteve</MDBCardTitle>
                                <span>
                        <MDBBtn className="add-product btn-outline-blue" onClick={this.toggleCreatingModal}>Klient i ri
                    </MDBBtn>
                    </span>
                            </MDBCardHeader>
                            <MDBRow className="clients-container ">
                                {
                                    this.state.clients
                                        .map((client, key) => (
                                                <MDBCard key={key}
                                                         className="client-card col-11 col-sm-11 col-md-5 col-lg-5 col-xl-3">
                                                    <MDBCardTitle
                                                        className="client-data client-data-title client-card-title">Të
                                                        dhënat
                                                        e përdoruesit:
                                                        <button className="client-button"
                                                                onClick={() => this.toggleModalAndSetProduct(client)}> Përditëso </button>
                                                    </MDBCardTitle>
                                                    <div className="client-info">
                                                        <hr className="hr-client"/>
                                                        <div className="client-data">
                                                            <MDBCol className="client-name client-info">Emri dhe
                                                                mbiemri:</MDBCol>
                                                            <MDBCol
                                                                className="client-name"> &nbsp;{client.fullname}</MDBCol>
                                                        </div>
                                                        <div className="client-data">
                                                            <MDBCol className="client-name client-info">Email:</MDBCol>
                                                            <MDBCol className="client-name"> &nbsp;{client.email}</MDBCol>
                                                        </div>
                                                        <div className="client-data">
                                                            <MDBCol className="client-name client-info">Telefon:</MDBCol>
                                                            <MDBCol className="client-name"> &nbsp;{client.phone}</MDBCol>
                                                        </div>
                                                    </div>
                                                    <div className="client-footer">
                                                        <MDBFooter className="footer-statusi">Aktiv</MDBFooter>
                                                    </div>


                                                </MDBCard>

                                            )
                                        )
                                }

                                <MDBModal className="client-md-update" isOpen={this.state.showModal}
                                          toggle={this.toggleModal}
                                          centered>
                                    <MDBModalHeader className="modal-title" toggle={this.toggleModal}>Të dhënat e
                                        klientit</MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Emri dhe mbiemri:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.client?.fullname}
                                                    placeholder={this.state.client?.fullname}
                                                    getValue={this.handleGetValueFullName}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Email:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.client?.email}
                                                    placeholder={this.state.client?.email}
                                                    getValue={this.handleGetValueCEmail}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Phone:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.client?.phone}
                                                    placeholder={this.state.client?.phone}
                                                    getValue={this.handleGetValuePhone}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                    </MDBModalBody>
                                    <MDBModalFooter className="client-modal-footer">
                                        <MDBBtn className="modal-btns" color="danger"
                                                onClick={this.confirmDeleteMyClient}><i
                                            className="fas fa-trash"/> &nbsp;Fshij </MDBBtn>
                                        <MDBBtn className="modal-btns" color="success"
                                                onClick={this.updateOrCreateClient}
                                        ><i className="far fa-edit"/> &nbsp; {this.state.isCreating ? "Krijo" : "Përditëso"}
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModal>
                            </MDBRow>
                        </>
                }
            </>
        );
    }
}

export default Clients;