import React from 'react';
import ChartSection1 from './sections/ChartSection1';

const DashboardPage = () => {
    return (
        <React.Fragment>
            <ChartSection1/>
        </React.Fragment>
    )
}

export default DashboardPage;