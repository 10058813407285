import React, {Component} from "react";

import {
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow,
} from "mdbreact";
import "../index.css"
import httpClient from "../AxiosConfig";
import {toast, Toaster} from "react-hot-toast";
import {confirmAlert} from "react-confirm-alert";


class Users extends Component {
    state = {
        users: [],
        showModal: false,
        user: {
            userGroupId: 3,
            isActive: 1
        },
        isCreating: false,
        loading: true
    }

    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            isCreating: false,
        });
    };

    toggleCreatingModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            isCreating: true,
            user: {
                userGroupId: 3
            }
        });
    };

    toggleModalAndSetUser = (user) => {
        this.setState({
            showModal: !this.state.showModal,
            user: user,
            isCreating: false,
        });
    };

    getMyUsers = () => {
        httpClient.get(`user/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success("Përdoruesit u shkarkuan me sukses!");
                    this.setState({users: res.data, loading: false});
                } else {
                    toast.error("Gabim gjatë shkarkimit të përdoruesve." + res.status);
                }
            })
            .catch(err => {
                toast.error("Gabim gjatë shkarkimit të përdoruesve." + err?.response?.status)
            })
    }

    componentDidMount() {
        this.getMyUsers();
    }

    handleGetValue = (newValue) => {
        let user = this.state.user;
        if (!user) return;
        user.username = newValue
        this.setState({
            user: user
        });
    }

    handleGetValueFName = (newValue) => {
        let user = this.state.user;
        if (!user) return;
        user.firstname = newValue
        this.setState({
            user: user
        });
    }

    handleGetValueLName = (newValue) => {
        let user = this.state.user;
        if (!user) return;
        user.lastname = newValue
        this.setState({
            user: user
        });
    }

    handleGetValueEmail = (newValue) => {
        let user = this.state.user;
        if (!user) return;
        user.email = newValue
        this.setState({
            user: user
        });
    }

    handleGetValuePassword = (newValue) => {
        let user = this.state.user;
        if (!user) return;
        user.password = newValue
        this.setState({
            user: user
        });
    }


    confirmDeleteMyUser = () => {
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: 'Konfirmo fshirjen e përdoruesit.',
                message: 'Dëshironi të konfirmoni veprimin?',
                buttons: [
                    {
                        label: 'Po',

                        onClick: () => {
                            this.deleteMyUser()
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                        }
                    }
                ]
            })
        })
    }


    deleteMyUser = () => {
        httpClient.delete(`user/delete?id=` + this.state.user.id)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Përdoruesi u fshi me sukses!');
                    this.getMyUsers()
                } else {
                    toast.error('Gabim gjatë fshierjes së përdoruesit.' + res.status);

                }
            }).catch(err => {
            toast.error('Gabim gjate fshierjes se përdoruesit ' + err?.response?.status);
        })
    }

    updateOrCreateUser = () => {
        if (!this.state.user?.username || this.state.user.username?.length <= 0) {
            toast.error("Të mbushet username!");
            return;
        } else if (!this.state.user?.firstname || this.state.user.firstname?.length <= 0) {
            toast.error("Të mbushet emri!");
            return;
        } else if (!this.state.user?.lastname || this.state.user.lastname?.length <= 0) {
            toast.error("Të mbushet mbiemri!");
            return;
        } else if (!this.state.user?.email || this.state.user.email?.length <= 0) {
            toast.error("Të mbushet email!");
            return;
        } else if (!this.state.user?.userGroupId || this.state.user.userGroupId?.length <= 0) {
            toast.error("Të zgjedhet roli!");
            return;
        } else if (!this.state.user?.isActive || this.state.user.isActive?.length <= 0) {
            toast.error("Të zgjedhet statusi!");
            return;
        }

        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: this.state.isCreating ? 'Konfirmo përdoruesin e ri' : 'Keni ndryshuar përdoruesin',
                message: 'Dëshironi të konfirmoni veprimin?',
                buttons: [
                    {
                        label: 'Po',

                        onClick: () => {
                            if (this.state.isCreating)
                                this.createUser()
                            else
                                this.updateUser()
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                            this.getMyUsers();
                        }
                    }
                ]
            });
        })
    }

    updateUser = () => {
        httpClient.put(`user/update`, this.state.user)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Përdoruesi u përditësua me sukses!');
                    this.getMyUsers();
                } else {
                    toast.error("Ndodhi një gabim gjatë përditësimit të përdoruesit." + res.status)
                }
            }).catch((error) => {
            toast.error("Ndodhi një gabim gjatë përditësimit të përdoruesit." + error?.response?.status);
        })
    }

    createUser = () => {
        let user = this.state.user;

        httpClient.post(`user/register`, user)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Përdoruesi u përditësua me sukses!');
                    this.getMyUsers();
                } else {
                    toast.error("Ndodhi një gabim gjatë krijimit të përdoruesit." + res.status)
                }
            }).catch((error) => {
            toast.error("Ndodhi një gabim gjatë krijimit të përdoruesit." + error?.response?.status);
        })
    }

    handleChangeSelect = (e) => {
        let user = this.state.user;
        user.userGroupId = parseInt(e.target.value);
        this.setState({
            user: user
        })
    }

    handleChangeChk = (e) => {
        let user = this.state.user;
        user.isActive = e.target.checked ? 1 : 2;
        this.setState({
            user: user
        })
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <div className="spinner-border text-primary clients-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                            <div><Toaster/></div>
                            <MDBCardHeader className="title-and-button">
                                <MDBCardTitle className="panel-tittle">Lista e përdoruesve</MDBCardTitle>
                                <span>
                        <MDBBtn className="add-product btn-outline-blue"
                                onClick={this.toggleCreatingModal}
                        >Përdorues i ri
                    </MDBBtn>
                    </span>
                            </MDBCardHeader>
                            <MDBRow className="users-container ">

                                {
                                    this.state.users
                                        .map((user, key) => (
                                                <MDBCard key={key}
                                                         className="client-card user-card col-11 col-sm-11 col-md-5 col-lg-3">
                                                    <MDBCardTitle className="user-title">
                                                        <p className="user-name">{user.username}</p>
                                                        <span className="update-client-span">
                                                    <button className="client-button"
                                                            onClick={() => this.toggleModalAndSetUser(user)}> Përditëso </button>
                                                </span>
                                                    </MDBCardTitle>
                                                    <div className="detail-user">
                                                        <div className="user-details"><strong>Emri dhe
                                                            mbiemri:</strong><span
                                                            className="users-details">&nbsp;{user.firstname}&nbsp;{user.lastname}</span>
                                                        </div>
                                                        <div className="user-details"><strong>Email:</strong><span
                                                            className="users-details ">{user.email}</span></div>
                                                        <div className="user-details"><strong>Roli:</strong><span
                                                            className="users-details">{
                                                            user.userGroupId === 1 ? "Admin" : user.userGroupId === 2 ? "Moderator" : "Klient"}</span>
                                                        </div>
                                                        <div className="user-details"><strong>Statusi:</strong><span
                                                            className="users-details">{
                                                            user.isActive === 1 ?
                                                                <span className="status-active">&nbsp;Aktiv</span> :
                                                                <span
                                                                    className="status-inactive">&nbsp;Jo-aktiv</span>}</span>
                                                        </div>

                                                    </div>
                                                </MDBCard>

                                            )
                                        )
                                }

                                <MDBModal className="client-md-update" isOpen={this.state.showModal}
                                          toggle={this.toggleModal}
                                          centered>
                                    <MDBModalHeader className="modal-title"
                                                    toggle={this.toggleModal}>Përdoruesi</MDBModalHeader>
                                    <MDBModalBody>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Username:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.user?.username}
                                                    placeholder={this.state.user?.username}
                                                    getValue={this.handleGetValue}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Emri:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.user?.firstname}
                                                    placeholder={this.state.user?.firstname}
                                                    getValue={this.handleGetValueFName}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Mbiemri:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.user?.lastname}
                                                    placeholder={this.state.user?.lastname}
                                                    getValue={this.handleGetValueLName}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Email:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    value={this.state.user?.email}
                                                    placeholder={this.state.user?.email}
                                                    getValue={this.handleGetValueEmail}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Password:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <MDBInput
                                                    className="modal-input-client"
                                                    type={"password"}
                                                    value={this.state.user?.password}
                                                    placeholder={this.state.user?.password}
                                                    getValue={this.handleGetValuePassword}/>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Roli i përdoruesit:</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <select className="browser-default custom-select"
                                                        onChange={this.handleChangeSelect}
                                                >
                                                    <option value="1">Admin</option>
                                                    <option value="2">Moderator</option>
                                                    <option selected value="3">Klient</option>

                                                </select>
                                            </MDBCol>
                                        </MDBRow>
                                        <hr className="modal-hr"/>
                                        <MDBRow>
                                            <MDBCol className="modal-label">
                                                <label>Statusi i përdoruesit</label>
                                            </MDBCol>
                                            <MDBCol className="modal-info">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input"
                                                           id="defaultChecked"
                                                           onChange={this.handleChangeChk}
                                                           value={parseInt(this.state.user?.isActive) === 1}
                                                           checked={parseInt(this.state.user?.isActive) === 1}
                                                    />
                                                    <label className="custom-control-label"
                                                           htmlFor="defaultChecked">Aktiv</label>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBModalBody>
                                    <MDBModalFooter className="client-modal-footer">
                                        <MDBBtn className="modal-btns" color="danger"
                                                onClick={this.confirmDeleteMyUser}><i
                                            className="fas fa-trash"/> &nbsp;Fshij </MDBBtn>
                                        <MDBBtn className="modal-btns" color="success"
                                                onClick={this.updateOrCreateUser}
                                        ><i className="far fa-edit"/> &nbsp; {this.state.isCreating ? "Krijo" : "Përditëso"}
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModal>
                            </MDBRow>
                        </>
                }
            </>
        );
    }
}

export default Users;
