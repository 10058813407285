import React from 'react'
import {MapContainer, TileLayer} from 'react-leaflet'

const MapsPage = () => {
    return (
        <MapContainer center={[42.6026, 20.9030]} zoom={9} style={{height: 800, width: '100%'}}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </MapContainer>
    )
}

export default MapsPage;