import React from 'react';
import {Switch} from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import MapsPage from './pages/MapsPage';
import Postings from "./pages/Postings";
import Products from "./pages/Products";
import Clients from "./pages/Clients";
import ProtectedRoute from "./utils/ProtectedRoute";
import Users from "./Users";
import Offers from "./geonetComponents/Offers";
import CaseDetails from "./pages/sections/CaseDetails";
import {Redirect} from "react-router";
import ProductCategory from "./pages/ProductCategory";

const Routes = () => {
    return (
        <Switch>
            <ProtectedRoute path='/profile' component={ProfilePage}/>
            <ProtectedRoute path='/maps' component={MapsPage}/>
            <ProtectedRoute path={`/postings/:caseId`} component={CaseDetails}/>
            <ProtectedRoute path='/postings' exact component={Postings}/>
            <ProtectedRoute path='/clients' component={Clients}/>
            <ProtectedRoute path='/products' component={Products}/>
            <ProtectedRoute path='/product-categories' component={ProductCategory}/>
            <ProtectedRoute path='/users' component={Users}/>
            <ProtectedRoute path='/offers' exact component={Offers}/>
            <ProtectedRoute path='/' exact component={DashboardPage}/>
            <Redirect to="/404"/>
        </Switch>
    );
}

export default Routes;
