import React from 'react';
import {MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBRow} from "mdbreact";
import {apiHost} from "../../Settings";
import {Link, useRouteMatch} from "react-router-dom";

const CaseCard = (props) => {
    const caseDetails = props.caseDetails;
    let match = useRouteMatch();

    return (
        <>

            <MDBCard className="case-card">
                <Link key={caseDetails.id} to={`${match?.url}/${caseDetails.id}`}>
                    <MDBCardImage className="img-fluid" src={
                        apiHost + caseDetails.casePhoto}/>
                    <MDBCardBody className="posting-card-body">
                        <MDBCardTitle className="case-title-posting">
                            <div className="subject-issue">Regjistruar nga:</div>
                            &nbsp;
                            <div className="card-registered-by"> {caseDetails.registeredBy}</div>
                        </MDBCardTitle>
                        <MDBCardTitle className="case-title-posting">
                            <div className="subject-issue">Klienti:</div>
                            &nbsp;
                            <div className="card-registered-by"> {caseDetails.client}</div>
                        </MDBCardTitle>
                        <MDBCardTitle className="case-title-posting">
                            <div className="subject-issue">Produkti:</div>
                            &nbsp;
                            <div>  {caseDetails.product}</div>
                        </MDBCardTitle>
                        <MDBCardTitle className="case-title-posting">
                            <div className="subject-issue">Subjekti:</div>
                            &nbsp;
                            <div className="card-registered-by"> {caseDetails.subject}</div>
                        </MDBCardTitle>
                        <hr className="postings-hr"/>
                        <MDBRow className="status-created-on">
                            <div className="col-6 created-on-date">
                                {caseDetails.createdOn}
                            </div>
                            <div className="col-6 case-statuses">{parseInt(caseDetails.statusId) === 1
                                ? <span className="open-status">Hapur </span> :
                                <span className="clossed-status">Mbyllur </span>}
                            </div>
                        </MDBRow>
                    </MDBCardBody>
                </Link>
            </MDBCard>

        </>
    );

}

export default CaseCard;