import React, {Component} from "react";
import httpClient from "../../AxiosConfig";
import {
    MDBBadge,
    MDBBtn,
    MDBCard,
    MDBCardHeader,
    MDBCardImage,
    MDBCardTitle,
    MDBCol,
    MDBContainer,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
} from "mdbreact";
import "../../index.css"
import {toast, Toaster} from "react-hot-toast";
import {confirmAlert} from "react-confirm-alert";
import {apiHost} from "../../Settings";
import Dropzone from "react-dropzone";

class Products extends Component {
    state = {
        products: [],
        showModal: false,
        product: {},
        isCreating: false,
        loading: true,
        categories: [],
        makes: [],
        mainFiles: [],
        additionalFiles: []
    }

    toggleModal = (product) => {
        this.setState({
            showModal: !this.state.showModal,
            product: product ?? {},
            mainFiles: product?.mainFiles ?? [],
            additionalFiles: product?.additionalFiles ?? []
        }, () => {
            setTimeout(() => {
                this.setState({
                    isCreating: false,
                })
            }, 500)
        });
    };

    getCategories = () => {
        httpClient.get(`product/categories`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    const allCategories = []
                    res.data.forEach(item => {
                        allCategories.push({
                            id: item.id,
                            name: item.name
                        })

                        if (item.children?.length > 0) {
                            item.children.forEach(child => {
                                allCategories.push({
                                    id: child.id,
                                    name: child.name
                                })

                                if (child.children?.length > 0) {
                                    child.children.forEach(subChild => {
                                        allCategories.push({
                                            id: subChild.id,
                                            name: subChild.name
                                        })
                                    })
                                }
                            })
                        }
                    });

                    this.setState({
                        categories: allCategories
                    })
                } else {
                    toast.error("Ndodhi nje gabim gjate shkarkimit te kategorive " + res.status);
                }
            }).catch(err => {
            toast.error("Ndodhi nje gabim gjate shkarkimit te kategorive " + err?.response?.status);
        })
    }

    getProductMakes = () => {
        httpClient.get(`product/product-enums`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.setState({
                        makes: res.data
                    })
                } else {
                    toast.error("Ndodhi nje gabim gjate shkarkimit te markave " + res.status);
                }
            }).catch(err => {
            toast.error("Ndodhi nje gabim gjate shkarkimit te markave " + err?.response?.status);
        })
    }

    toggleCreatingModal = () => {
        this.setState({
            showModal: !this.state.showModal,
            isCreating: true,
            product: {}
        });
    };

    getMyProduct = () => {
        httpClient.get(`product/list`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.setState({products: res.data, loading: false});
                } else {
                    toast.error("Ndodhi nje gabim gjate shkarkimit te produkteve " + res.status);
                }
            }).catch(err => {
            toast.error("Ndodhi nje gabim gjate shkarkimit te produkteve " + err?.response?.status);
        })
    }

    handleGetValue = (newValue) => {
        let product = this.state.product;
        if (!product) return;
        product.productName = newValue
        this.setState({
            product: product
        });
    }

    handeChangeDescription = (newValue) => {
        let product = this.state.product;
        if (!product) return;
        product.description = newValue
        this.setState({
            product: product
        });
    }

    handeChangeSpecs = (newValue) => {
        let product = this.state.product;
        if (!product) return;
        product.specs = newValue
        this.setState({
            product: product
        });
    }

    handleChangeStatus = (e) => {
        let product = this.state.product;
        if (!product) return;
        product.statusId = e.target.checked ? 1 : 0
        this.setState({
            product: product
        });
    }

    handleChangeIsHeaderImage = (e) => {
        let product = this.state.product;
        if (!product) return;
        product.isHeaderImage = e.target.checked ? 1 : 0
        this.setState({
            product: product
        });
    }

    handleGetSelect = (e) => {
        let product = this.state.product;
        if (!product || !e.target.value || parseInt(e.target.value) <= 0) return;
        product.productCategoryId = e.target.value.length <= 0 ? null : parseInt(e.target.value)

        this.setState({
            product: product
        });
    }

    handleGetMakeSelect = (e) => {
        let product = this.state.product;
        if (!product || !e.target.value || parseInt(e.target.value) <= 0) return;
        product.make = e.target.value.length <= 0 ? null : parseInt(e.target.value)

        this.setState({
            product: product
        });
    }

    componentDidMount() {
        this.getMyProduct();
        toast.success("Produktet u shkarkuan me sukses.")

        this.getCategories();
        this.getProductMakes();
    }

    updateOrCreateProduct = () => {
        if (!this.state.product?.productName || this.state.product.productName?.length <= 0) {
            toast.error("Të plotësohet fusha e produktit!");
            return;
        }
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: this.state.isCreating ? 'Konfirmo produktin e ri' : 'Keni ndryshuar produktin',
                message: 'Dëshironi të konfirmoni veprimin?',
                buttons: [
                    {
                        label: 'Jo',
                        onClick: () => {
                            this.getMyProduct();
                        }
                    },
                    {
                        label: 'Po',
                        onClick: () => {
                            if (this.state.isCreating)
                                this.createProduct()
                            else
                                this.updateProduct()
                        }
                    },
                ]
            });
        })
    }

    deleteMyProduct = (id) => {
        this.setState({
            showModal: false
        }, () => {
            confirmAlert({
                title: 'Keni kërkuar fshirjen e produktit',
                message: 'Dëshironi të konfirmoni fshirjen?',
                buttons: [
                    {
                        label: 'Po',
                        onClick: () => {
                            if (this.state.product?.id <= 0) {
                                toast.error("Zgjedhni në produkt për të fshirë");
                                return
                            }

                            httpClient.delete('product/delete?id=' + this.state.product.id)
                                .then(res => {
                                    if (200 <= res.status && res.status < 400) {
                                        toast.success("Produkti u fshi!");
                                        this.getMyProduct();
                                    } else {
                                        toast.error("Fshirja e produktit dështoi! " + res.status);
                                    }

                                }).catch(err => {
                                toast.error("Fshirja e produktit dështoi! " + err?.response?.status);
                            })
                        }
                    },
                    {
                        label: 'Jo',
                        onClick: () => {
                        }
                    }
                ]
            });
        })
    }

    deleteMainProductImage = () => {
        this.setState({
            ...this.state,
            product: {...this.state.product, url: null}
        }, () => this.updateProduct())
    }

    updateProduct = () => {
        httpClient.put(`product/update`, this.state.product)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Produkti u përditësua me sukses!');
                    this.getMyProduct();
                } else {
                    toast.error("Ndodhi një gabim gjatë përditësimit të produktit." + res.status)
                }
            }).catch((err) => {
            toast.error("Ndodhi një gabim gjatë përditësimit të produktit." + err?.response?.status);
        })

        this.updateHeaderImage()
        this.updateOtherImages()
    }

    updateHeaderImage = () => {
        let formData = new FormData();
        if (this.state.mainFiles.length <= 0 || this.state.product.id <= 0) {
            return;
        }

        formData.append('id', this.state.product.id)
        formData.append('image', this.state.mainFiles[0])

        httpClient.put(`product/update/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.setState({
                        mainFiles: []
                    });
                } else {
                    toast.error("Ndodhi një gabim gjatë ngarkimi të imazhit kryesor." + res.status)
                }
            }).catch((err) => {
            toast.error("Ndodhi një gabim gjatë ngarkimi të imazhit kryesor." + err?.response?.status);
        })
    }

    updateOtherImages = () => {
        let formData = new FormData();
        if (this.state.additionalFiles.length <= 0 || this.state.product.id <= 0) {
            return;
        }

        formData.append('id', this.state.product.id)
        if (this.state.product.isHeaderImage === 1) {
            formData.append('type', 'header')
        }

        this.state.additionalFiles.forEach(item => {
            formData.append('files', item)
        })

        httpClient.put(`product/upload-other-images`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.setState({
                        product: {
                            ...this.state.product,
                            isHeaderImage: false,
                            additionalImages: []
                        }
                    })
                } else {
                    toast.error("Ndodhi një gabim gjatë ngarkimi të imazheve të tjera." + res.status)
                }
            }).catch((err) => {
            toast.error("Ndodhi një gabim gjatë ngarkimit të imazheve të tjera." + err?.response?.status);
        })
    }

    deleteArticleImages = () => {
        let formData = new FormData();
        formData.append('id', this.state.product.id)

        httpClient.put(`product/remove-other-images`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    this.setState({
                        showModal: false
                    })
                    toast.success("Fshirja e imazheve u perfundua me sukses." + res.status)
                } else {
                    toast.error("Ndodhi një gabim gjatë fshierjes të imazheve." + res.status)
                }
            }).catch((err) => {
            toast.error("Ndodhi një gabim gjatë fshierjes të imazheve." + err?.response?.status);
        })
    }

    createProduct = () => {
        let product = this.state.product;
        if (product?.productName?.length <= 0) {
            toast.error("Vendos emer valid te produktit")
            return;
        }

        httpClient.post(`product/create`, {
            id: 0,
            productName: product.productName,
            statusId: 1,
            url: null,
            productCategoryId: product.productCategoryId
        })
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    toast.success('Produkti u krijua me sukses!');
                    this.getMyProduct();
                } else {
                    toast.error("Ndodhi një gabim gjatë krijimit të produktit." + res.status)
                }
            }).catch((error) => {
            toast.error("Ndodhi një gabim gjatë krijimit të produktit." + error?.response?.status);
        })
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <div className="spinner-border text-primary clients-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>

                            <div><Toaster/></div>
                            <MDBCardHeader className="title-and-button">
                                <MDBCardTitle className="panel-tittle">Lista e
                                    produkteve</MDBCardTitle>
                                <span>
                        <MDBBtn className="add-product btn-outline-blue"
                                onClick={this.toggleCreatingModal}>Produkt i ri
                    </MDBBtn>
                    </span>
                            </MDBCardHeader>
                            <MDBContainer className="cards-container row">
                                {
                                    this.state.products
                                        .map((product, key) => (
                                                <MDBCol key={key} xl={"4"} lg={"5"} md={"5"} sm={"11"}
                                                        xs={"11"}>
                                                    <MDBCard className="product-card">
                                                        <MDBBadge
                                                            className="product-badge">Produkti:</MDBBadge>

                                                        {product.url &&
                                                            <MDBCardImage className="img-product" src={
                                                                apiHost + product.url}/>
                                                        }
                                                        <MDBCardTitle
                                                            className="product-name">{product.productName}</MDBCardTitle>

                                                        {product.description &&
                                                            <p className={'pl-2 pr-2 ellipsis'} style={{
                                                                fontSize: '12px'
                                                            }}>{product.description}</p>}

                                                        <button className="product-button"
                                                                onClick={() => this.toggleModal(product)}> Detaje
                                                        </button>
                                                    </MDBCard>
                                                </MDBCol>
                                            )
                                        )
                                }

                                <MDBModal className="product-modal" isOpen={this.state.showModal}
                                          toggle={this.toggleModal}
                                          centered>
                                    <MDBModalHeader className="modal-title"
                                                    toggle={this.toggleModal}>Të dhënat e
                                        produktit
                                    </MDBModalHeader>
                                    <MDBModalBody>
                                        <p>{this.state.isCreating
                                            ? "Vendos të dhënat për produktin"
                                            : "Klikoni mbi produkt për të ndryshuar produktin:"}</p>

                                        <hr className="modal-hr"/>

                                        {this.state.product?.url ?
                                            <>
                                                <MDBCardImage className="img-product" src={
                                                    apiHost + this.state.product?.url}/>

                                                <div
                                                    className="ml-2 d-flex align-items-center justify-content-end">
                                                    <label htmlFor="statusi">Fshij imazhin: </label>
                                                    <MDBBtn
                                                        className="modal-btns d-flex align-items-center justify-content-center"
                                                        color="danger"
                                                        onClick={this.deleteMainProductImage}><i
                                                        className="fas fa-trash"/> &nbsp;</MDBBtn>
                                                </div>
                                            </> :
                                            <div className={'p-5'}>
                                                <Dropzone onDrop={acceptedFiles => {
                                                    if (acceptedFiles.length > 1) {
                                                        toast("Nuk mund të ngarkoni më shumë se një imazh.")
                                                        acceptedFiles.splice(1, acceptedFiles.length - 1)
                                                    }

                                                    this.setState({
                                                        mainFiles: acceptedFiles
                                                    })
                                                }}>
                                                    {({
                                                          getRootProps,
                                                          getInputProps,
                                                          isDragActive
                                                      }) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />

                                                                {
                                                                    this.state.mainFiles.length > 0 ?
                                                                        this.state.mainFiles.map((file, key) => (
                                                                            <div
                                                                                key={key}>{file.name}</div>
                                                                        ))
                                                                        : isDragActive ?
                                                                            <p className="drag-and-drop-text">Imazhi
                                                                                këtu</p> :
                                                                            <p className="drag-and-drop-text">Vendos
                                                                                apo kliko për të
                                                                                ngarkuar
                                                                                imazhin</p>
                                                                }
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>}

                                        <MDBInput
                                            label="Emri i produktit:"
                                            className="modal-input-text"
                                            value={this.state.product?.productName}
                                            placeholder={this.state.product?.productName}
                                            getValue={this.handleGetValue}
                                        />

                                        <textarea placeholder="Përshkrimi i produktit:"
                                                  className="form-control"
                                                  style={{fontSize: "15px", fontWeight: 500}}
                                                  value={this.state.product?.description}
                                                  onChange={e => this.handeChangeDescription(e.target.value)}
                                                  rows={4}
                                        />

                                        <textarea placeholder="Specifikat e produktit:"
                                                  className="form-control mt-2"
                                                  style={{fontSize: "15px", fontWeight: 500}}
                                                  value={this.state.product?.specs}
                                                  onChange={e => this.handeChangeSpecs(e.target.value)}
                                                  rows={3}
                                        />

                                        <select className="browser-default custom-select mt-3"
                                                onChange={this.handleGetSelect}
                                        >
                                            <option value={""}>Kategoria (nëse ka)</option>

                                            {
                                                this.state.categories.map(item => {
                                                    return <option value={item.id.toString()}
                                                                   selected={item.id === this.state.product.productCategoryId}
                                                                   key={item.id}>{item.name}</option>
                                                })
                                            }
                                        </select>

                                        <select className="browser-default custom-select mt-3"
                                                onChange={this.handleGetMakeSelect}
                                        >
                                            <option value={""}>Marka (nëse ka)</option>

                                            {
                                                this.state.makes.map(item => {
                                                    return <option value={item.value.toString()}
                                                                   selected={item.value === this.state.product.make}
                                                                   key={item.value}>{item.name}</option>
                                                })
                                            }
                                        </select>

                                        <div className="mt-3 ml-2 d-flex align-items-center">
                                            <label htmlFor="statusi">Statusi (aktiv / jo-aktiv ne
                                                web): </label>
                                            <input
                                                id="statusi"
                                                type="checkbox"
                                                className="modal-input-text ml-2 mb-2"
                                                checked={this.state.product?.statusId === 1}
                                                onChange={this.handleChangeStatus}
                                            />
                                        </div>

                                        <div className="ml-2 d-flex align-items-center">
                                            <label htmlFor="statusi">Imazhi eshte per Header (ne
                                                Web): </label>
                                            <input
                                                id="statusi"
                                                type="checkbox"
                                                className="modal-input-text ml-2 mb-2"
                                                checked={this.state.product?.isHeaderImage ?? false}
                                                onChange={this.handleChangeIsHeaderImage}
                                            />
                                        </div>

                                        <div className="ml-2 d-flex align-items-center">
                                            <label htmlFor="statusi">Fshij imazhet e
                                                artikullit: </label>

                                            <MDBBtn
                                                className="modal-btns d-flex align-items-center justify-content-center"
                                                color="danger"
                                                onClick={this.deleteArticleImages}><i
                                                className="fas fa-trash"/> &nbsp;</MDBBtn>
                                        </div>

                                        <div className={'d-flex mt-4'}>
                                            {
                                                this.state.product?.images?.map(image => {
                                                    return <MDBCardImage className={'mr-2'}
                                                                         style={{width: "150px"}}
                                                                         src={
                                                                             apiHost + image?.url}/>
                                                })
                                            }
                                        </div>

                                        <div className={'p-5'}>
                                            <Dropzone onDrop={acceptedFiles => {
                                                if (acceptedFiles.length > 20) {
                                                    toast("Nuk mund të ngarkoni më shumë se 20 imazhe.")
                                                    acceptedFiles.splice(20, acceptedFiles.length - 1)
                                                }

                                                this.setState({
                                                    additionalFiles: acceptedFiles
                                                })
                                            }}>
                                                {({getRootProps, getInputProps, isDragActive}) => (
                                                    <section>
                                                        <div {...getRootProps()}
                                                             className={'d-flex align-items-center'}>
                                                            <input {...getInputProps()} />

                                                            {
                                                                this.state.additionalFiles.length > 0 ?
                                                                    this.state.additionalFiles.map((file, key) => (
                                                                        <div
                                                                            key={key}>{file.name}</div>
                                                                    ))
                                                                    : isDragActive ?
                                                                        <p className="drag-and-drop-text mb-0 pb-0">Imazhet
                                                                            këtu</p> :
                                                                        <p className="drag-and-drop-text mb-0 pb-0">Vendos
                                                                            apo kliko për të ngarkuar
                                                                            imazhe të tjera</p>
                                                            }

                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                        </div>
                                    </MDBModalBody>
                                    <MDBModalFooter>
                                        {!this.state.isCreating &&
                                            <MDBBtn className="modal-btns" color="danger"
                                                    onClick={this.deleteMyProduct}><i
                                                className="fas fa-trash"/> &nbsp;Fshij </MDBBtn>}

                                        <MDBBtn className="modal-btns" color="success"
                                                onClick={this.updateOrCreateProduct}
                                        ><i className="far fa-edit"/> &nbsp; {this.state.isCreating ? "Krijo" : "Përditëso"}
                                        </MDBBtn>
                                    </MDBModalFooter>
                                </MDBModal>
                            </MDBContainer>
                        </>
                }
            </>
        );
    }
}

export default Products;
