import React, {Component} from "react";
import httpClient from "../../../AxiosConfig";
import {MDBContainer} from "mdbreact";
import "../../../index.css"
import CaseCard from "../CaseCard";


class TeamCases extends Component {
    state = {
        cases: [],
        loading: true,
    }

    componentDidMount() {
        this.getMyCases();
    }

    getMyCases = () => {
        const userId = localStorage.getItem('id')

        httpClient.get(`case/teamcases?userid=${userId}`)
            .then(res => {
                const cases = res.data;
                this.setState({
                    cases: cases,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    }

    render() {
        const {cases, loading} = this.state;
        const {showCases} = this.props

        const casesToShow = showCases ? cases : cases.filter(caseDetails => caseDetails.statusId === 1);

        return (
            <MDBContainer className="cards-container row">
                {
                    loading ? <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        : casesToShow.map((caseDetails, key) =>
                            <CaseCard key={key} caseDetails={caseDetails}/>
                        )
                }
            </MDBContainer>
        );
    }
}

export default TeamCases;