import React from 'react';
import {MDBFooter} from 'mdbreact';
import "../index.css"

const Footer = () => {
    return (
        <MDBFooter color="blue" className="text-center footer-b font-small darken-2 footer-main">
            <p className="footer-copyright mb-0 py-3 text-center footer-color">
                &copy; {new Date().getFullYear()} Copyright: <a href="https://geotech.al"> Geotech </a>
            </p>
        </MDBFooter>
    );
}

export default Footer;
