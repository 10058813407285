import React, {useState} from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBContainer, MDBInput} from 'mdbreact';
import "../../index.css"
import logo from "./Geotech-logo-new.png";
import {toast, Toaster} from "react-hot-toast";
import httpClient from "../../AxiosConfig";

function loginUser(credentials) {
    return httpClient.post('user/login', credentials)
}

function SignIn(props) {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = e => {
        if (username.length <= 0) {
            toast.error("Ju lutem mbushni fushën përdoruesi.")
            return
        } else if (password.length <= 0) {
            toast.error("Ju lutem mbushni fushën fjalëkalimi.")
            return;
        }

        e.preventDefault();
        loginUser({
            username,
            password
        }).then(response => {
            if (200 <= response.status && response.status < 400) {
                if (parseInt(response.data.isAdmin) !== 1 && parseInt(response.data.isAdmin) !== 2) {
                    toast.error("Nuk jeni i autorizuar për kyçje")
                    return;
                }

                localStorage.setItem('token', response.data.token)
                localStorage.setItem('id', response.data.id)
                localStorage.setItem('name', response.data.name)
                localStorage.setItem('username', response.data.username)
                localStorage.setItem('isAdmin', response.data.isAdmin)
                props.history.push('/');
            } else if (500 >= response.status) {
                toast.error("Ndodhi gabim gjatë kyçjes." + response.status)
            } else {
                toast.error("Keni gabuar të dhënat e kyçjes." + response.status)
            }
        }).catch(err => {
            if (500 >= err?.response?.status) {
                toast.error("Ndodhi gabim gjatë kyçjes." + err?.response?.status)
            } else {
                toast.error("Keni gabuar të dhënat e kyçjes." + err?.response?.status)
            }
        })
    }
    return (
        <>
            <Toaster/>
            <MDBContainer className="">
                <MDBContainer className="logo-sign-in">
                    <a className="logo-wrapper waves-effect">
                        <img alt="MDB React Logo" className="img-fluid sign-in-logo" src={logo}/>
                    </a>
                </MDBContainer>

                <MDBContainer md="9" lg="7" xl="" className="mx-auto mt-3 sign-in-container">
                    <MDBCard className="card-sign-in">
                        <div className="text-center">
                            <h3 className="dark-grey-text mb-5 identification"><strong>Identifikohu</strong></h3>
                        </div>
                        <MDBCardBody className="sign-in-form">

                            <form>
                                <MDBInput className="sign-in-input" label="Përdoruesi" group type="email" validate
                                          error="wrong" success="right"
                                          onChange={e => setUserName(e.target.value)}/>
                                <MDBInput className="sign-in-input" label="Fjalëkalimi" group type="password" validate
                                          containerClass="mb-0"
                                          onChange={e => setPassword(e.target.value)
                                }

                                />
                                <div className="text-center pt-3 mb-3 ">
                                    <MDBBtn type="button" color="blue" rounded className="z-depth-1a btn-sign-in"
                                            onClick={handleSubmit}
                                    >
                                        Kyçuni
                                    </MDBBtn>
                                </div>
                            </form>

                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </MDBContainer>
        </>


    );

}

export default SignIn;
