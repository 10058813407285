import React from 'react';
import {withRouter} from "react-router-dom";
import {MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer} from 'mdb-react-ui-kit';
import {MDBIcon,} from 'mdbreact';
import "../index.css"

const TopNavigation = ({history, setCollapseId, menuDisplay}) => {
    const handleClick = () => {
        localStorage.clear()
        history.push('/signin')

    }
    const parentName = history.location.pathname.includes('postings') ||
    history.location.pathname.includes('products') ||
    history.location.pathname.includes('clients') ? 'Geotech' :
        history.location.pathname.includes('offers') ? 'Geonet' :
            history.location.pathname.includes('users') ? 'Përdoruesit' : 'Geotech / Geonet';

    const currentName =
        history.location.pathname.includes('postings') ? 'Postimet' :
            history.location.pathname.includes('products') ? 'Produktet' :
                history.location.pathname.includes('clients') ? 'Klientet' :
                    history.location.pathname.includes('offers') ? 'Ofertat' :
                        history.location.pathname.includes('users') ? 'Lista e përdoruesve' : "";

    const toggleCollapse = id => {
        setCollapseId(id)
    };

    return (
        <>
            <nav className='navbar navbar-expand-lg white nav-breadcrump'>
                <MDBContainer className="nav-container" fluid="true">
                    <MDBBreadcrumb className="breadcrumb-items">
                        <MDBBreadcrumbItem>
                            <div className="breadcrump-item-link">{
                                parentName
                            }</div>
                        </MDBBreadcrumbItem>
                        <MDBBreadcrumbItem>
                            <span className="breadcrump-item-link">{
                                currentName
                            }</span>
                        </MDBBreadcrumbItem>
                    </MDBBreadcrumb>
                    <div className="header-buttons">
                        {menuDisplay &&
                            <button className="toggle-button"
                                    onClick={() => toggleCollapse('navcollapse')}>
                                <MDBIcon icon="th"/> &nbsp;Shfleto
                            </button>
                        }

                        <button className="log-out-button" onClick={handleClick}>
                            <MDBIcon icon="sign-out-alt"/> Dil
                        </button>

                    </div>

                </MDBContainer>

            </nav>

        </>

    )
}
export default withRouter(TopNavigation);




