import React, {Component} from 'react';
import {
    MDBBadge,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardTitle,
    MDBCol,
    MDBListGroup,
    MDBListGroupItem,
    MDBRow
} from 'mdbreact';
import {Bar, Pie} from 'react-chartjs-2';
import httpClient from "../../../AxiosConfig";
import "../../../index.css"

class ChartSection1 extends Component {
    state = {
        myValues: [],
        myValue: {},
        dataPie: [],
        dataPie2: [],
        dataPieNames: [],
        dataPieNames2: [],
        dataBarNames: [],
        allCases: {}
    }

    componentDidMount() {
        this.getMyValues();
    }

    getMyValues = () => {
        httpClient.get(`user/dashboard`)
            .then(res => {
                if (200 <= res.status && res.status < 400) {
                    //toaster

                    const myValues = res.data;
                    const dataPie = res.data.filter(c => c.openCases >= 1).map(c => c.openCases)
                    const dataPie2 = res.data.filter(c => c.closedCases >= 1).map(c => c.closedCases)
                    const nameLabels = res.data.filter(c => c.openCases >= 1).map(c => c.name)
                    const nameLabels2 = res.data.filter(c => c.closedCases >= 1).map(c => c.name)
                    const nameLabels3 = res.data.filter(c => c.closedCases >= 1 || c.openCases >= 1).map(c => c.name)
                    const allCases = res.data.find(c => c.id === 0)

                    this.setState({
                        myValues: myValues,
                        dataPie: dataPie,
                        dataPie2: dataPie2,
                        dataPieNames: nameLabels,
                        dataPieNames2: nameLabels2,
                        dataBarNames: nameLabels3,
                        allCases: allCases
                    });
                }
            })
        //catch
        // and
        //toaster
    }

    render() {
        const dataBar = {
            labels: this.state.dataBarNames,
            datasets: [
                {
                    label: 'Rastet e mbyllura',
                    data: this.state.dataPie2,
                    backgroundColor: 'rgba(245, 74, 85, 0.5)',
                    borderWidth: 1
                }, {
                    label: 'Rastet e hapura',
                    data: this.state.dataPie,
                    backgroundColor: 'rgba(245,177,50,0.5)',
                    borderWidth: 1
                }
            ]
        };

        const barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    barPercentage: 1,
                    gridLines: {
                        display: true,
                        color: 'rgba(0, 0, 0, 0.1)'
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        color: 'rgba(0, 0, 0, 0.1)'
                    },
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }

        const dataPie = {
            labels: this.state.dataPieNames,
            datasets: [
                {
                    data: this.state.dataPie,
                    backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#ac64ad'],
                    hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774', '#da92db']
                }
            ]
        }
        const dataPie1 = {
            labels: this.state.dataPieNames2,
            datasets: [
                {
                    data: this.state.dataPie2,
                    backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360', '#ac64ad'],
                    hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870', '#A8B3C5', '#616774', '#da92db']
                }
            ]
        }

        return (
            <>
                <MDBCardHeader className="title-and-button">
                    <MDBCardTitle className="panel-tittle">Statistikat admin</MDBCardTitle>
                </MDBCardHeader>
                <div className="mt-4 align-items-center ">

                    <MDBRow>
                        <MDBCol md="12" className="mb-4 col-lg-8">
                            <div md="12" className="mb-4 open-close-cases-nr">
                                <MDBCard className="mb-4">
                                    <MDBCardBody>
                                        <MDBListGroup className="list-group-flush">
                                            <MDBListGroupItem className="open-cases-line">
                                                Rastet e hapura &nbsp;&nbsp;
                                                <MDBBadge color="success-color" pill className="float-right">
                                                    {this.state.allCases?.openCases}
                                                </MDBBadge>
                                            </MDBListGroupItem>
                                            <MDBListGroupItem>
                                                Rastet e mbyllura &nbsp;&nbsp;
                                                <MDBBadge color="danger-color" pill className="float-right">
                                                    {this.state.allCases?.closedCases}
                                                </MDBBadge>
                                            </MDBListGroupItem>
                                        </MDBListGroup>
                                    </MDBCardBody>
                                </MDBCard>
                            </div>
                            <MDBCard className="mb-4 cases-pie-card">
                                <MDBCardBody>
                                    <Bar data={dataBar} height={500} options={barChartOptions}/>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>

                        <MDBCol className="cases-pie col-lg-4">
                            <MDBCard className="mb-4 ">
                                <MDBCardHeader>Rastet e hapura</MDBCardHeader>
                                <MDBCardBody>
                                    <Pie data={dataPie} height={150} options={{responsive: true}}/>
                                </MDBCardBody>
                            </MDBCard>
                            <MDBCard className="mb-4 ">
                                <MDBCardHeader>Rastet e mbyllura</MDBCardHeader>
                                <MDBCardBody>
                                    <Pie data={dataPie1} height={150} options={{responsive: true}}/>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>

                </div>
            </>

        )
    }
}

export default ChartSection1;

